import BigNumber from 'bignumber.js';
import * as React from 'react'
import { useWallet } from 'use-wallet'


const Header = (props) => {
    const wallet = useWallet();

    return <>
        <nav className="ps--navigation" data-controller="navbar" id="myTopnav">
            <div className="ps--navigation__logo">
                <a data-turbolinks="false" href="/">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width={1476} height={183} viewBox="0 0 1476 183" fill="none">
                        <g clipPath="url(#clip0)">
                            <path d="M316.6 164.6C304.2 176.9 287.6 183 266.8 183C246 183 229.4 176.9 216.9 164.6C204.4 152.3 198.2 136.6 198.2 117.5C198.2 98.4002 204.4 82.7002 216.9 70.5002C229.4 58.3002 246 52.2002 266.8 52.2002C287.6 52.2002 304.2 58.3002 316.6 70.5002C329 82.7002 335.2 98.4002 335.2 117.5C335.2 136.6 329 152.3 316.6 164.6ZM266.8 149.7C275.8 149.7 283 146.7 288.3 140.7C293.6 134.7 296.2 127 296.2 117.5C296.2 107.9 293.6 100.2 288.3 94.2002C283 88.2002 275.8 85.2002 266.8 85.2002C257.6 85.2002 250.4 88.2002 245.2 94.2002C240 100.2 237.4 108 237.4 117.5C237.4 127.1 240 134.8 245.2 140.8C250.4 146.8 257.6 149.7 266.8 149.7Z" fill="black" />
                            <path d="M353 179.2V0H392.2V179.2H353Z" fill="black" />
                            <path d="M540.8 179.2H492.9L453.7 125.7V179.2H414.5V0H453.7V107.3L493.9 55.9H540L490.9 114.3L540.8 179.2Z" fill="black" />
                            <path d="M596.6 182.5C578.7 182.5 564.5 176.4 554.1 164.3C543.7 152.2 538.5 136.6 538.5 117.5C538.5 98.6998 543.9 83.1998 554.6 70.8998C565.3 58.5998 579.5 52.4998 597.1 52.4998C605.2 52.3998 613.2 54.2998 620.4 58.0998C626.9 61.5998 632.3 66.8998 635.8 73.4998V55.7998H674.7V179.2H635.8V161.8C632.4 167.9 627.1 172.9 620.1 176.8C612.9 180.6 604.8 182.6 596.6 182.5ZM607.9 150C617.3 150 624.5 146.9 629.7 140.8C634.9 134.7 637.4 126.9 637.4 117.5C637.4 108.3 634.8 100.6 629.7 94.4998C624.6 88.3998 617.3 85.2998 607.9 85.2998C599 85.2998 591.9 88.2998 586.5 94.2998C581.1 100.3 578.4 108 578.4 117.5C578.4 127.1 581.1 134.9 586.5 140.9C591.9 147 599 150 607.9 150Z" fill="black" />
                            <path d="M750.2 183C732.5 183 718.3 179.2 707.7 171.5C697.1 163.8 691.8 154 691.8 142.1H730.7C730.7 146.4 732.6 149.9 736.3 152.6C740.1 155.3 744.9 156.7 750.9 156.7C755.2 156.7 758.6 155.7 761.3 153.6C763.9 151.6 765.4 148.5 765.3 145.2C765.3 139.6 762.3 136.1 756.3 134.7L726.1 127C716 124.4 708.4 120.1 703.1 113.9C697.8 107.7 695.2 100.2 695.2 91.4002C695.2 79.8002 700 70.4002 709.5 63.1002C719 55.8002 731.9 52.2002 747.9 52.2002C765.6 52.2002 779.3 55.6002 788.7 62.4002C798.1 69.2002 803 78.7002 803 90.6002H764.3C764.5 86.7002 762.8 83.0002 759.8 80.6002C756.8 78.2002 752.7 77.0002 747.4 77.0002C743.1 77.0002 739.7 77.9002 737.2 79.8002C734.7 81.5002 733.3 84.3002 733.4 87.2002C733.4 92.7002 736.9 96.3002 743.9 98.2002L773.1 106.2C782.8 108.8 790.5 113 796 118.9C801.5 124.7 804.5 132.5 804.3 140.5C804.3 153.3 799.5 163.6 789.8 171.3C780.1 179 766.9 183 750.2 183Z" fill="black" />
                            <path d="M878.4 148.7C882.9 148.8 887.5 148.5 892 147.9V179.1C883.4 180.5 874.6 181.2 865.9 181.1C862 181.1 858 180.9 854.1 180.3C850.1 179.6 846.3 178.5 842.6 176.8C838.6 175.2 835 172.7 832.1 169.5C829.4 166.4 827 162 825.1 156.2C823 149.6 822 142.7 822.2 135.7V0.700195H861.4V55.8002H889.3V86.8002H861.4V127.8C861.4 136.2 862.9 141.7 865.7 144.6C868.5 147.4 872.8 148.8 878.4 148.7Z" fill="black" />
                            <path d="M963.2 182.5C945.3 182.5 931.1 176.4 920.7 164.3C910.3 152.2 905.1 136.6 905.1 117.5C905.1 98.7 910.5 83.2 921.2 70.9C932 58.6 946.1 52.5 963.7 52.5C971.8 52.3 979.8 54.3 987 58.1C993.5 61.6 998.9 66.9 1002.3 73.5V55.8H1041.2V179.2H1002.3V161.8C998.9 167.9 993.6 172.9 986.6 176.8C979.4 180.6 971.4 182.6 963.2 182.5ZM974.5 150C983.9 150 991.1 146.9 996.3 140.8C1001.4 134.7 1004 126.9 1004 117.5C1004 108.3 1001.4 100.6 996.3 94.5C991.2 88.4 983.9 85.3 974.5 85.3C965.6 85.3 958.5 88.3 953.1 94.3C947.7 100.3 945 108 945 117.5C945 127.1 947.7 134.9 953.1 140.9C958.4 147 965.6 150 974.5 150Z" fill="black" />
                            <path d="M1059 179.2V55.7998H1098.2V81.5998C1100.2 72.6998 1104.5 65.8998 1110.9 60.9998C1117 56.1998 1124.5 53.6998 1132.3 53.6998C1135.7 53.5998 1139.2 53.9998 1142.5 54.6998V90.9998C1138.6 90.4998 1134.7 90.2998 1130.7 90.1998C1109 90.1998 1098.2 103.9 1098.2 131.4V179L1059 179.2Z" fill="black" />
                            <path d="M1216.8 148.7C1221.3 148.8 1225.9 148.5 1230.4 147.9V179.1C1221.8 180.5 1213 181.2 1204.3 181.1C1200.4 181.1 1196.4 180.9 1192.5 180.3C1188.5 179.6 1184.7 178.5 1181 176.8C1177 175.2 1173.4 172.7 1170.5 169.5C1167.8 166.4 1165.4 162 1163.5 156.2C1161.4 149.6 1160.4 142.7 1160.6 135.7V0.700195H1199.8V55.8002H1227.7V86.8002H1199.8V127.8C1199.8 136.2 1201.2 141.7 1204.1 144.6C1207 147.5 1211.2 148.8 1216.8 148.7Z" fill="black" />
                            <path d="M1374.5 111.1C1374.5 116.8 1374.1 122.6 1373.5 128.2H1282.4C1282.7 136.2 1285.5 142.4 1290.6 146.9C1295.7 151.4 1302.6 153.6 1311.3 153.5C1323.2 153.5 1330.9 149.7 1334.3 142H1372.7C1370.8 154.1 1364.2 164 1352.9 171.6C1341.6 179.2 1327.7 183 1311.3 183C1290.3 183 1273.8 177.2 1261.6 165.6C1249.5 154 1243.4 138 1243.4 117.5C1243.4 97.3998 1249.5 81.4998 1261.6 69.7998C1273.7 58.0998 1289.8 52.2998 1310 52.2998C1329.1 52.2998 1344.6 57.7998 1356.6 68.6998C1368.5 79.4998 1374.5 93.6998 1374.5 111.1ZM1282.4 103.9H1335.9C1335.7 95.6998 1333.4 89.3998 1329 84.9998C1324.6 80.5998 1318.2 78.3998 1309.8 78.3998C1301.4 78.3998 1294.8 80.6998 1290 85.3998C1285.1 89.9998 1282.5 96.1998 1282.4 103.9Z" fill="black" />
                            <path d="M1392.3 179.2V55.7998H1431.5V81.5998C1433.5 72.6998 1437.8 65.8998 1444.2 60.9998C1450.3 56.1998 1457.8 53.6998 1465.6 53.6998C1469 53.5998 1472.5 53.9998 1475.8 54.6998V90.9998C1471.9 90.4998 1468 90.2998 1464 90.1998C1442.3 90.1998 1431.5 103.9 1431.5 131.4V179L1392.3 179.2Z" fill="black" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M173.512 18.3047C161.434 6.10157 146.055 0 127.502 0H51.3566V47.9409V73.5206C67.9637 78.7951 79.9965 94.3384 79.9965 112.692C79.9965 131.046 67.9637 146.589 51.3566 151.864V179H92.7601V129.378H127.564C146.118 129.378 161.496 123.277 173.575 111.073C185.653 98.8703 191.755 83.4296 191.755 64.689C191.755 45.9485 185.653 30.5078 173.512 18.3047ZM141.697 86.9784C136.343 92.7687 129.494 95.695 121.089 95.695H92.6978V33.8077H121.089C129.432 33.8077 136.28 36.7339 141.697 42.5242C147.052 48.3144 149.729 55.7235 149.729 64.7513C149.791 73.7791 147.052 81.1882 141.697 86.9784Z" fill="black" />
                            <path className="circle" d="M30 143C46.5685 143 60 129.569 60 113C60 96.4315 46.5685 83 30 83C13.4315 83 0 96.4315 0 113C0 129.569 13.4315 143 30 143Z" fill="#FF3465" />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width={1476} height={183} fill="white" />
                            </clipPath>
                        </defs>
                    </svg> */}
                </a>
            </div>
            <div className="ps--navigation__app-menu">
                <a data-turbolinks="false" href="/projects">Projects</a>
                <a data-turbolinks="false" href="/allocations">Allocations</a>
                <a data-turbolinks="false" href="/dashboard">Dashboard</a>
                {/* <a  data-turbolinks="false" href="/quests">{props.address}</a> */}
            </div>
            <div className="ps--navigation__crypto-menu" data-controller="nft">
                <button type="button" data-nft-target="extremelyRareNftBadge" className="ps--nft-badge d-none" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={26} viewBox="0 0 32 26" fill="none">
                        <g clipPath="url(#clip0_1441_34763)">
                            <path d="M23.53 8.25999H31.93L16 25.45L23.53 8.25999Z" fill="#08A189" />
                            <path d="M0 8.26L8.46 0V8.26H0Z" fill="#51FF7A" />
                            <path d="M23.53 0V8.26H31.93L23.53 0Z" fill="#0FD29E" />
                            <path d="M8.46001 8.26L16 25.59L23.53 8.26H8.46001ZM8.46001 0H16L8.46001 8.26V0ZM16 0L23.53 8.26V0H16Z" fill="#00EA8E" />
                            <path d="M23.53 8.26H8.46001L16 0L23.53 8.26ZM23.53 8.26L27.74 6.88L31.98 8.26L27.74 9.65L23.53 8.26Z" fill="#00FF89" />
                            <path d="M8.46 8.26H0L15.93 25.59L8.46 8.26ZM8.46 8.26L16 6.88L23.53 8.26L16 9.65L8.46 8.26Z" fill="#00FF56" />
                            <path d="M0 8.26L4.23 6.88L8.46 8.26L4.23 9.65L0 8.26Z" fill="#23FA6C" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1441_34763">
                                <rect width={32} height="25.59" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </button>
                <ul className="dropdown-menu dropdown-menu-end ps--nft-badge__dropdown">
                    <div>
                        <h2>Green Paulie</h2>
                        <img src="https://cdn.polkastarter.com/packs/media/images/nfts/green-badge-6e1497c9f4cd32c44bf1d6bf528c46b4.png" />
                        <h3>
                            You are the only holder of this
                            <span className="text-color-success">Extremely Rare</span> Green
                            Paulie.
                        </h3>
                        <p>
                            You have a guaranteed allowlisting spot to all TocoSwap IDO's
                            in 2022.
                            <a target="_blank" href="https://blog.polkastarter.com/introducing-green-paulie/">Learn More</a>
                        </p>
                        <a className="btn btn-sm btn-outline-secondary w-100" href="https://www.binance.com/en/nft/goods/detail?nftInfoId=12692034">Check Green Paulie NFT</a>
                    </div>
                </ul>
                <button type="button" data-nft-target="commonNftBadge" className="ps--nft-badge d-none" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width={33} height={32} viewBox="0 0 33 32" fill="none">
                        <path d="M23.7931 11.2645H32.1898L16.2559 28.4546L23.7931 11.2645Z" fill="#A10C15" />
                        <path d="M0.255859 11.2645L8.71867 3V11.2645H0.255859Z" fill="#FF7ACF" />
                        <path d="M23.7931 3V11.2645H32.1898L23.7931 3Z" fill="#D21736" />
                        <path d="M8.71866 11.2645L16.2559 28.5868L23.793 11.2645H8.71866ZM8.71866 3H16.2559L8.71866 11.2645V3ZM16.2559 3L23.793 11.2645V3H16.2559Z" fill="#EA0042" />
                        <path d="M23.793 11.2645H8.71866L16.2559 3L23.793 11.2645ZM23.793 11.2645L28.0245 9.87604L32.2559 11.2645L28.0245 12.6529L23.793 11.2645Z" fill="#FF0059" />
                        <path d="M8.71867 11.2645H0.255859L16.1898 28.5868L8.71867 11.2645ZM8.71867 11.2645L16.2559 9.87607L23.7931 11.2645L16.2559 12.6529L8.71867 11.2645Z" fill="#FF0089" />
                        <path d="M0.255859 11.2645L4.48726 9.87607L8.71867 11.2645L4.48726 12.6529L0.255859 11.2645Z" fill="#FA349E" />
                    </svg>
                </button>
                <ul className="dropdown-menu dropdown-menu-end ps--nft-badge__dropdown">
                    <div>
                        <h2>Paulie</h2>
                        <img src="https://cdn.polkastarter.com/packs/media/images/nfts/common-badge-2cce793a1b4bf1e102eddc5b742ba843.png" />
                        <h3>
                            You have the <span className="common">TocoSwap NFT</span>, you are
                            skipping the cooldown period as a reward.
                        </h3>
                        <p>
                            There’s only a limited amount of these NFTs, so you’re one of the
                            lucky one to get it. <a target="_blank" href="#">Learn More</a>
                        </p>
                        <a className="btn btn-sm btn-outline-secondary w-100" href="https://app.refinable.com/collections/polkastarter-diamond-hands?tab=all-items">Check Paulie NFT</a>
                    </div>
                </ul>
                <button type="button" data-nft-target="rareNftBadge" className="ps--nft-badge d-none" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
                        <path d="M23.5372 11.2645H31.9339L16 28.4545L23.5372 11.2645Z" fill="#A18325" />
                        <path d="M0 11.2645L8.39669 3V11.2645H0Z" fill="#FFDCAA" />
                        <path d="M23.5372 3V11.2645H31.9339L23.5372 3Z" fill="#D2A838" />
                        <path d="M8.3967 11.2645L15.9339 28.5868L23.4711 11.2645H8.3967V11.2645ZM8.3967 3H15.9339L8.3967 11.2645V3ZM15.9339 3L23.4711 11.2645V3H15.9339Z" fill="#EAAD23" />
                        <path d="M23.5372 11.2645H8.3967L15.9339 3L23.5372 11.2645ZM23.5372 11.2645L27.7686 9.87603L32 11.2645L27.7686 12.6529L23.5372 11.2645Z" fill="#FFB826" />
                        <path d="M8.39669 11.2645H0L15.9339 28.5868L8.39669 11.2645ZM8.39669 11.2645L15.9339 9.87604L23.4711 11.2645L15.9339 12.6529L8.39669 11.2645Z" fill="#FFAC26" />
                        <path d="M0 11.2645L4.2314 9.87604L8.46281 11.2645L4.2314 12.6529L0 11.2645Z" fill="#FABE5D" />
                    </svg>
                </button>
                <ul className="dropdown-menu dropdown-menu-end ps--nft-badge__dropdown">
                    <div>
                        <h2>
                            Golden Paulie
                            <span className="ps--badge ps--warning">Looks Rare</span>
                        </h2>
                        <img src="https://cdn.polkastarter.com/packs/media/images/nfts/rare-badge-044d2a482e0c51b12a815fc7d865355d.png" />
                        <h3>
                            You have a <span className="rare">Rare TocoSwap NFT</span>, you
                            have guaranteed allowlisting spots as a reward.
                        </h3>
                        <p>
                            There’s only 10 of these NFTs, so you’re one of the lucky one to
                            get it. <a target="_blank" href="#">Learn More</a>
                        </p>
                        <a className="btn btn-sm btn-outline-secondary w-100" href="https://app.refinable.com/collections/polkastarter-diamond-hands?tab=all-items">Check Golden Paulie NFT</a>
                    </div>
                </ul>
                <a className="ps--navigation__item ps--navigation__pols-wallet d-none" id="stakingButton" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
                        <path d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z" fill="#E8307D" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M22.437 9.64195C21.3624 8.54732 19.9942 8 18.3436 8H11.5691V14.5949C13.0466 15.068 14.1171 16.4622 14.1171 18.1086C14.1171 19.755 13.0466 21.1492 11.5691 21.6223V24.0565H15.2527V19.6053H18.3491C19.9998 19.6053 21.3679 19.0581 22.4426 17.9634C23.5171 16.8688 24.06 15.4837 24.06 13.8027C24.06 12.1216 23.5171 10.7366 22.437 9.64195ZM19.6065 15.802C19.1301 16.3214 18.5208 16.5839 17.773 16.5839H15.2471V11.0326H17.773C18.5153 11.0326 19.1245 11.2951 19.6065 11.8145C20.0829 12.3338 20.321 12.9985 20.321 13.8083C20.3266 14.6181 20.0829 15.2827 19.6065 15.802Z" fill="white" />
                        <path d="M9.66903 20.8272C11.1431 20.8272 12.3381 19.6225 12.3381 18.1362C12.3381 16.65 11.1431 15.4452 9.66903 15.4452C8.19497 15.4452 7 16.65 7 18.1362C7 19.6225 8.19497 20.8272 9.66903 20.8272Z" fill="white" />
                    </svg>
                    <span className="ps--navigation__pols-wallet__amount stakedBalancePing" data-target="navbar.stakedBalance" data-balance={0}>0</span>
                    <span className="ps--navigation__pols-wallet__ticker">POLS</span>
                </a>
                {wallet.status === "connected" && <a style={{ cursor: "pointer" }} className="ps--navigation__item ps--navigation__wallet" data-target="navbar.balanceButton" id="balanceButton" href="javascript:void(0)">
                    <span style={{ cursor: "pointer" }} className="ps--navigation__wallet__amount balancePing" data-target="navbar.balance" id="balanceLabel" data-balance="0.000276533043700389">{(new BigNumber(wallet.balance).toNumber() / 10 ** 18).toFixed(4)} BNB</span>
                    <div style={{ cursor: "pointer" }} className="ps--navigation__wallet__address" id="addressHolder">
                        <span id="addressLabel" data-target="navbar.address">{wallet.account.slice(0, 10)}...</span>
                    </div>
                    {/* {console.log(wallet)} */}
                </a>}
                {
                    wallet.status !== "connected" && <div>
                        <button onClick={() => {
                            wallet.connect();
                            console.log(wallet);
                        }}>
                            Connect
                        </button>
                    </div>
                }
                <div data-controller="chain" id="chainSelectMenu">
                    <button type="button" className="btn btn-outline-secondary dropdown-toggle ps--navigation__item ps--select-network" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
                            <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#F3BA2F" />
                            <path d="M12.116 14.404L16 10.52L19.886 14.406L22.146 12.146L16 6L9.856 12.144L12.116 14.404ZM6 16L8.26 13.74L10.52 16L8.26 18.26L6 16ZM12.116 17.596L16 21.48L19.886 17.594L22.146 19.853L16 26L9.856 19.856L9.853 19.853L12.116 17.596ZM21.48 16L23.74 13.74L26 16L23.74 18.26L21.48 16ZM18.292 15.998H18.294V16L16 18.294L13.709 16.004L13.705 16L13.709 15.997L14.11 15.595L14.305 15.4L16 13.706L18.293 15.999L18.292 15.998Z" fill="white" />
                        </svg>
                        <span data-chain-target="chainLabel">Binance</span>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                            <a className="dropdown-item" data-action="click->chain#switchChain" data-chain="ethereum" href="javascript:void(0)">
                                Ethereum
                                <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
                                    <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#627EEA" />
                                    <path d="M16.498 4V12.87L23.995 16.22L16.498 4Z" fill="white" fillOpacity="0.602" />
                                    <path d="M16.498 4L9 16.22L16.498 12.87V4Z" fill="white" />
                                    <path d="M16.498 21.968V27.995L24 17.616L16.498 21.968Z" fill="white" fillOpacity="0.602" />
                                    <path d="M16.498 27.995V21.967L9 17.616L16.498 27.995Z" fill="white" />
                                    <path d="M16.498 20.573L23.995 16.22L16.498 12.872V20.573Z" fill="white" fillOpacity="0.2" />
                                    <path d="M9 16.22L16.498 20.573V12.872L9 16.22Z" fill="white" fillOpacity="0.602" />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a className="dropdown-item" data-action="click->chain#switchChain" data-chain="binance" href="javascript:void(0)">
                                Binance Smart Chain
                                <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
                                    <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#F3BA2F" />
                                    <path d="M12.116 14.404L16 10.52L19.886 14.406L22.146 12.146L16 6L9.856 12.144L12.116 14.404ZM6 16L8.26 13.74L10.52 16L8.26 18.26L6 16ZM12.116 17.596L16 21.48L19.886 17.594L22.146 19.853L16 26L9.856 19.856L9.853 19.853L12.116 17.596ZM21.48 16L23.74 13.74L26 16L23.74 18.26L21.48 16ZM18.292 15.998H18.294V16L16 18.294L13.709 16.004L13.705 16L13.709 15.997L14.11 15.595L14.305 15.4L16 13.706L18.293 15.999L18.292 15.998Z" fill="white" />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a className="dropdown-item" data-action="click->chain#switchChain" data-chain="polygon" href="javascript:void(0)">
                                Polygon
                                <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
                                    <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#8247E5" />
                                    <path d="M21.2959 12.5767C20.9122 12.3535 20.4133 12.3535 19.9911 12.5767L16.9978 14.2884L14.9638 15.4046L11.9705 17.1163C11.5867 17.3395 11.0878 17.3395 10.6657 17.1163L8.28633 15.7767C7.90258 15.5535 7.63394 15.1442 7.63394 14.6977V12.0558C7.63394 11.6093 7.86418 11.2 8.28633 10.9767L10.6273 9.67444C11.0111 9.45117 11.5099 9.45117 11.9321 9.67444L14.273 10.9767C14.6568 11.2 14.9255 11.6093 14.9255 12.0558V13.7675L16.9594 12.614V10.9023C16.9594 10.4558 16.7291 10.0465 16.307 9.82327L11.9705 7.36746C11.5867 7.1442 11.0878 7.1442 10.6657 7.36746L6.25237 9.82327C5.83026 10.0465 5.59999 10.4558 5.59999 10.9023V15.8512C5.59999 16.2977 5.83026 16.707 6.25237 16.9302L10.6657 19.3861C11.0494 19.6093 11.5483 19.6093 11.9705 19.3861L14.9638 17.7116L16.9978 16.5582L19.9911 14.8837C20.3749 14.6605 20.8738 14.6605 21.2959 14.8837L23.6369 16.1861C24.0207 16.4093 24.2893 16.8186 24.2893 17.2651V19.907C24.2893 20.3535 24.059 20.7628 23.6369 20.9861L21.2959 22.3256C20.9122 22.5488 20.4133 22.5488 19.9911 22.3256L17.6502 21.0233C17.2664 20.8 16.9978 20.3907 16.9978 19.9442V18.2325L14.9638 19.3861V21.0977C14.9638 21.5442 15.1941 21.9535 15.6162 22.1767L20.0295 24.6326C20.4133 24.8558 20.9122 24.8558 21.3343 24.6326L25.7476 22.1767C26.1313 21.9535 26.4 21.5442 26.4 21.0977V16.1488C26.4 15.7023 26.1697 15.293 25.7476 15.0698L21.2959 12.5767Z" fill="white" />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a className="dropdown-item" data-action="click->chain#switchChain" data-chain="celo" href="javascript:void(0)">
                                Celo
                                <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
                                    <g clipPath="url(#clip0_1010_23853)">
                                        <path d="M13.0526 27.0526C17.5297 27.0526 21.1579 23.4244 21.1579 18.9474C21.1579 14.4703 17.5297 10.8421 13.0526 10.8421C8.57558 10.8421 4.94737 14.4703 4.94737 18.9474C4.94737 23.4244 8.57558 27.0526 13.0526 27.0526ZM13.0526 30C6.94863 30 2 25.0514 2 18.9474C2 12.8434 6.94863 7.89474 13.0526 7.89474C19.1566 7.89474 24.1053 12.8434 24.1053 18.9474C24.1053 25.0514 19.1566 30 13.0526 30Z" fill="#FBCC5C" />
                                        <path d="M18.9474 21.1579C23.4244 21.1579 27.0527 17.5297 27.0527 13.0526C27.0527 8.57558 23.4244 4.94737 18.9474 4.94737C14.4703 4.94737 10.8421 8.57558 10.8421 13.0526C10.8421 17.5297 14.4703 21.1579 18.9474 21.1579ZM18.9474 24.1053C12.8434 24.1053 7.89476 19.1566 7.89476 13.0526C7.89476 6.94863 12.8434 2 18.9474 2C25.0514 2 30 6.94863 30 13.0526C30 19.1566 25.0514 24.1053 18.9474 24.1053Z" fill="#35D07F" />
                                        <path d="M19.3129 24.1053C20.0792 23.1768 20.6274 22.0893 20.9192 20.9192C22.0863 20.6274 23.1739 20.0792 24.1053 19.3128C24.064 20.6657 23.7722 21.9979 23.2447 23.2476C21.9979 23.7722 20.6657 24.064 19.3129 24.1053ZM11.0809 11.0808C9.91371 11.3726 8.82613 11.9208 7.89476 12.6872C7.93602 11.3343 8.22781 10.0021 8.75539 8.75538C10.0021 8.23074 11.3373 7.93896 12.6872 7.89474C11.9209 8.82317 11.3727 9.91074 11.0809 11.0808Z" fill="#5EA33B" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1010_23853">
                                            <rect width={28} height={28} fill="white" transform="translate(2 2)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
                <a className="ps--navigation__item ps--navigation__connect-wallet d-none" data-target="navbar.connectButton" data-action="click -> navbar#openMetamask" id="connectWalletButton" href="javascript:void(0)">
                    Connect Wallet
                </a>
            </div>
            <div className="ps--burger" data-action="click->navbar#openMobile">
                <input id="js-burgerButton" type="checkbox" />
                <div className="ps--burger__bun">
                    <div className="burger" />
                </div>
            </div>
        </nav>
        <div className="d-none" data-controller="safe-banner chain" id="wrongNetworkAlert">
            <div className="alert alert-danger alert-sticky" data-target="safe-banner.banner" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} viewBox="0 0 17 17" fill="none">
                    <path d="M15.716 14.029L9.38201 2.15401C9.00601 1.44801 7.99401 1.44801 7.61701 2.15401L1.28401 14.029C0.929007 14.695 1.41201 15.5 2.16701 15.5H14.834C15.588 15.5 16.071 14.695 15.716 14.029ZM8.50001 13.5C7.94801 13.5 7.50001 13.052 7.50001 12.5C7.50001 11.948 7.94801 11.5 8.50001 11.5C9.05201 11.5 9.50001 11.948 9.50001 12.5C9.50001 13.052 9.05201 13.5 8.50001 13.5ZM9.50001 10.5H7.50001V6.50001H9.50001V10.5Z" fill="#C95959" />
                </svg>
                <div style={{ display: 'inline-block' }}>
                    App network (<span id="wrongNetworkAlertAppNetwork" />) doesn’t
                    match to the network selected in wallet (Binance).
                </div>
                <a target="_blank" href="https://blog.polkastarter.com/how-to-add-binance-smart-chain-and-polygon-to-metamask/">Learn how to change network in wallet</a>
                or
                <button data-action="click -> chain#switchChain" className="btn btn-light btn-sm">
                    Change Network
                </button>
            </div>
        </div>
        <div className="ps--page-wrapper d-none" id="wrong-network-hero">
            <div className="ps--form__header">
                <div className="ps--container ps--container--small d-flex align-items-center">
                    <div className="ps--form__header__title">
                        <h1 className="ps--heading-md">Network Unavailable</h1>
                    </div>
                </div>
            </div>
            <div className="ps--container ps--container--small">
                <div className="ps--alert ps--alert--small ps--warning mb-2">
                    <div className="ps--alert__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none">
                            <g clipPath="url(#svg-warning-sign)">
                                <path d="M15.8 12.526L9.483 0.879999C9.3272 0.591063 9.08962 0.354521 8.8 0.199999C8.40652 -0.0105699 7.94582 -0.0572607 7.51809 0.0700826C7.09037 0.197426 6.73023 0.488502 6.516 0.879999L0.199999 12.526C0.0618912 12.7823 -0.00716199 13.0701 -0.000375534 13.3611C0.00641092 13.6522 0.0888035 13.9365 0.238706 14.186C0.388608 14.4356 0.600863 14.6419 0.854612 14.7846C1.10836 14.9273 1.39488 15.0015 1.686 15H14.314C14.593 15 14.8678 14.9313 15.114 14.8C15.3091 14.6965 15.4817 14.5553 15.6219 14.3847C15.7622 14.2142 15.8673 14.0175 15.9311 13.8061C15.995 13.5947 16.0164 13.3727 15.9941 13.153C15.9717 12.9333 15.9061 12.7202 15.801 12.526H15.8ZM8 13C7.80222 13 7.60888 12.9413 7.44443 12.8315C7.27998 12.7216 7.15181 12.5654 7.07612 12.3827C7.00043 12.2 6.98063 11.9989 7.01921 11.8049C7.0578 11.6109 7.15304 11.4327 7.29289 11.2929C7.43274 11.153 7.61093 11.0578 7.80491 11.0192C7.99889 10.9806 8.19996 11.0004 8.38268 11.0761C8.56541 11.1518 8.72159 11.28 8.83147 11.4444C8.94135 11.6089 9 11.8022 9 12C9 12.2652 8.89464 12.5196 8.7071 12.7071C8.51957 12.8946 8.26521 13 8 13ZM9 9.5C9 9.63261 8.94732 9.75978 8.85355 9.85355C8.75978 9.94732 8.63261 10 8.5 10H7.5C7.36739 10 7.24021 9.94732 7.14645 9.85355C7.05268 9.75978 7 9.63261 7 9.5V5.5C7 5.36739 7.05268 5.24021 7.14645 5.14645C7.24021 5.05268 7.36739 5 7.5 5H8.5C8.63261 5 8.75978 5.05268 8.85355 5.14645C8.94732 5.24021 9 5.36739 9 5.5V9.5Z" fill="black" />
                            </g>
                            <defs>
                                <clipPath id="svg-warning-sign">
                                    <rect width={16} height={16} fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="ps--alert__info">
                        <h2>
                            Select one of the supported networks in the network selector.
                        </h2>
                    </div>
                </div>
                <div className="ps--form">
                    <h5>
                        If you are not using MetaMask please see available tutorials below
                        on how to connect to our compatible chains.
                    </h5>
                    <hr />
                    If you need help setting up Binance Smart Chain, here is a great
                    tutorial:
                    <a target="_blank" className="btn btn-primary" href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain">
                        Learn how to Connect to BSC
                    </a>
                    <br />
                    If you need help setting up Polygon, here is a great tutorial:
                    <a target="_blank" className="btn btn-primary" href="https://docs.matic.network/docs/develop/metamask/config-matic/">
                        Learn how to Connect to Polygon
                    </a>
                    <br />
                    If you need help setting up Celo, here is a great tutorial:
                    <a target="_blank" className="btn btn-primary" href="https://docs.celo.org/getting-started/wallets/using-metamask-with-celo/manual-setup">
                        Learn how to Connect to Celo
                    </a>
                </div>
            </div>
        </div></>
}
export default Header