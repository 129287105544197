import React, { useEffect } from 'react';
import GetImage from '../Projects/GetImage';
import Sidebar from './components/Sidebar';
import TabContent from './components/TabContent';

function ProjectDetails(props) {

  useEffect(() => {
    console.log("in details ", props);

    return () => {

    }
  }, [])

  return (
    <div>
      <div id="app-content">

        <div className="ps--project-show__wrapper" data-controller="project-partial" data-id="45"
          data-pool-is-whitelisted="false">
          <div className="ps--container">
            <div className="alert alert-success fade show d-none" data-project-partial-target="whitelistingNotice"
              role="alert">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7 11.4L3.6 8L5 6.6L7 8.6L11 4.6L12.4 6L7 11.4Z"
                  fill="black"></path>
              </svg>
              Your wallet is whitelisted and you can join the token sale
            </div>
            <div className="ps--project-show">
              <div className="ps--project-show__secondary">
                <nav className="ps--project-show__breadcrumb" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <p >Projects</p>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {props.data.productName}
                    </li>
                  </ol>
                </nav>
                <div className="ps--project-show__status">
                  <div className="ps--project-show__date d-none" data-project-partial-target="nextDateHolder">
                    <span data-project-partial-target="nextDatePrefix"></span>
                    <span data-project-partial-target="nextDateLabel" data-partial-type="topNav"></span>
                    <div data-bs-toggle="tooltip" data-bs-placement="left" title="12 Aug 2021, 12:00 PM UTC"
                      data-controller="tooltip" data-project-partial-target="nextDateTooltip"
                      className="d-none">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                        fill="none">
                        <path
                          d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM9 12H7V7H9V12ZM8 6C7.4 6 7 5.6 7 5C7 4.4 7.4 4 8 4C8.6 4 9 4.4 9 5C9 5.6 8.6 6 8 6Z"
                          fill="#212121"></path>
                      </svg>
                    </div>
                  </div>
                  {/* {!img && <div className="ps--badge" data-project-partial-target="status">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ps--spinner" width="65px" height="65px"
                      viewBox="0 0 66 66">
                      <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33"
                        cy="33" r="30"></circle>
                    </svg>
                  </div>} */}
                  <div className="ps--project-show__share" data-controller="project-social" data-id="45">
                    <button type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none">
                        <path d="M17 6L12 0L7 6H11V17H13V6H17Z" fill="#828997"></path>
                        <path
                          d="M22 23H2C1.84623 23 1.69452 22.9646 1.55667 22.8965C1.41882 22.8283 1.29854 22.7293 1.20519 22.6071C1.11183 22.4849 1.04791 22.3429 1.01839 22.192C0.988873 22.041 0.994556 21.8854 1.035 21.737L4.035 10.737C4.09271 10.5253 4.21845 10.3385 4.39283 10.2053C4.56722 10.0721 4.78057 9.99996 5 10H8V12H5.764L3.31 21H20.69L18.236 12H16V10H19C19.2194 9.99996 19.4328 10.0721 19.6072 10.2053C19.7815 10.3385 19.9073 10.5253 19.965 10.737L22.965 21.737C23.0054 21.8854 23.0111 22.041 22.9816 22.192C22.9521 22.3429 22.8882 22.4849 22.7948 22.6071C22.7015 22.7293 22.5812 22.8283 22.4433 22.8965C22.3055 22.9646 22.1538 23 22 23Z"
                          fill="#828997"></path>
                      </svg>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <p className="dropdown-item" data-project-social-target="reddit"
                        >
                          Reddit
                          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32"
                            viewBox="0 0 33 32" fill="none">
                            <path
                              d="M16.4895 32C25.3261 32 32.4895 24.8366 32.4895 16C32.4895 7.16344 25.3261 0 16.4895 0C7.65295 0 0.489502 7.16344 0.489502 16C0.489502 24.8366 7.65295 32 16.4895 32Z"
                              fill="#FF4500"></path>
                            <path
                              d="M27.1562 16C27.1562 14.7088 26.1082 13.6608 24.817 13.6608C24.1808 13.6608 23.6194 13.9041 23.2077 14.3158C21.617 13.1743 19.4088 12.4257 16.9761 12.3322L18.0427 7.33566L21.5047 8.06549C21.5422 8.94502 22.272 9.65613 23.1702 9.65613C24.0872 9.65613 24.8357 8.90759 24.8357 7.99063C24.8357 7.07367 24.0872 6.32513 23.1702 6.32513C22.5153 6.32513 21.9539 6.6994 21.6919 7.26081L17.8182 6.43742C17.7059 6.4187 17.5936 6.43742 17.5001 6.49356C17.4065 6.5497 17.3503 6.64326 17.3129 6.75554L16.134 12.3322C13.6451 12.407 11.4182 13.1368 9.80883 14.3158C9.39713 13.9228 8.81702 13.6608 8.19947 13.6608C6.90824 13.6608 5.86029 14.7088 5.86029 16C5.86029 16.9544 6.42169 17.7591 7.24509 18.1333C7.20766 18.3579 7.18895 18.6012 7.18895 18.8444C7.18895 22.4374 11.362 25.338 16.527 25.338C21.6919 25.338 25.865 22.4374 25.865 18.8444C25.865 18.6012 25.8463 18.3766 25.8088 18.152C26.5761 17.7778 27.1562 16.9544 27.1562 16ZM11.1562 17.6655C11.1562 16.7485 11.9047 16 12.8217 16C13.7387 16 14.4872 16.7485 14.4872 17.6655C14.4872 18.5824 13.7387 19.331 12.8217 19.331C11.9047 19.331 11.1562 18.5824 11.1562 17.6655ZM20.4568 22.0631C19.3153 23.2047 17.1445 23.2795 16.5082 23.2795C15.872 23.2795 13.6825 23.186 12.5597 22.0631C12.3913 21.8947 12.3913 21.614 12.5597 21.4456C12.7281 21.2772 13.0088 21.2772 13.1772 21.4456C13.8884 22.1567 15.4229 22.4187 16.527 22.4187C17.6311 22.4187 19.1468 22.1567 19.8767 21.4456C20.0451 21.2772 20.3258 21.2772 20.4942 21.4456C20.6252 21.6327 20.6252 21.8947 20.4568 22.0631ZM20.1574 19.331C19.2404 19.331 18.4919 18.5824 18.4919 17.6655C18.4919 16.7485 19.2404 16 20.1574 16C21.0743 16 21.8229 16.7485 21.8229 17.6655C21.8229 18.5824 21.0743 19.331 20.1574 19.331Z"
                              fill="white"></path>
                          </svg>
                        </p>
                      </li>
                      <li>
                        <p className="dropdown-item" data-project-social-target="twitter"
                        >
                          Twitter
                          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32"
                            viewBox="0 0 33 32" fill="none">
                            <g clipPath="url(#svg-twitter)">
                              <path
                                d="M29.1282 9.61809C29.1476 9.89809 29.1476 10.1781 29.1476 10.4607C29.1476 19.071 22.5927 29.0013 10.6069 29.0013V28.9962C7.06627 29.0013 3.59918 27.9871 0.61853 26.0749C1.13337 26.1368 1.65079 26.1678 2.1695 26.1691C5.10369 26.1716 7.95401 25.1871 10.2624 23.3742C7.47401 23.3213 5.02885 21.5033 4.17466 18.8491C5.15143 19.0374 6.15789 18.9987 7.11659 18.7368C4.07659 18.1226 1.8895 15.4516 1.8895 12.3497C1.8895 12.3213 1.8895 12.2942 1.8895 12.2671C2.7953 12.7716 3.8095 13.0516 4.84692 13.0826C1.98369 11.1691 1.10111 7.36003 2.83014 4.38196C6.13853 8.45293 11.0198 10.9278 16.2598 11.1897C15.7347 8.92648 16.4521 6.55487 18.145 4.9639C20.7695 2.4968 24.8972 2.62325 27.3643 5.24648C28.8237 4.95874 30.2224 4.42325 31.5024 3.66454C31.0159 5.17293 29.9979 6.45422 28.6379 7.26842C29.9295 7.11616 31.1914 6.77035 32.3798 6.24261C31.505 7.55358 30.403 8.69551 29.1282 9.61809Z"
                                fill="#1D9BF0"></path>
                            </g>
                            <defs>
                              <clipPath id="svg-twitter">
                                <rect width="32" height="26.3226" fill="white"
                                  transform="translate(0.489502 3)"></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </p>
                      </li>
                      <li>
                        <p className="dropdown-item" data-project-social-target="telegram"
                        >
                          Telegram
                          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32"
                            viewBox="0 0 33 32" fill="none">
                            <path
                              d="M16.4895 31.3334C24.9579 31.3334 31.8228 24.4684 31.8228 16C31.8228 7.53165 24.9579 0.666687 16.4895 0.666687C8.02113 0.666687 1.15616 7.53165 1.15616 16C1.15616 24.4684 8.02113 31.3334 16.4895 31.3334Z"
                              fill="url(#paint0_linear)"></path>
                            <path
                              d="M11.5355 17.1207L13.3562 22.156C13.3562 22.156 13.5835 22.6273 13.8268 22.6273C14.0702 22.6273 17.6935 18.8587 17.6935 18.8587L21.7215 11.0787L11.6002 15.8213L11.5355 17.1207Z"
                              fill="#C8DAEA"></path>
                            <path
                              d="M13.9475 18.412L13.5982 22.1233C13.5982 22.1233 13.4522 23.2607 14.5888 22.1233C15.7255 20.986 16.8142 20.1093 16.8142 20.1093L13.9475 18.412Z"
                              fill="#A9C6D8"></path>
                            <path
                              d="M11.5682 17.3007L7.82684 16.0814C7.82684 16.0814 7.38017 15.9001 7.5235 15.4887C7.55284 15.4034 7.61284 15.3314 7.79017 15.2067C8.6195 14.6287 23.1368 9.41072 23.1368 9.41072C23.3461 9.33722 23.5713 9.32133 23.7888 9.36472C23.8487 9.38328 23.9025 9.41737 23.9449 9.46351C23.9873 9.50964 24.0167 9.56619 24.0302 9.62739C24.0561 9.73538 24.0668 9.84643 24.0622 9.95739C24.0622 10.0534 24.0495 10.1427 24.0408 10.2827C23.9522 11.7087 21.3075 22.3567 21.3075 22.3567C21.3075 22.3567 21.1495 22.9794 20.5822 23.0007C20.4425 23.0057 20.3032 22.9824 20.1727 22.9322C20.0422 22.882 19.9232 22.806 19.8228 22.7087C18.7095 21.7507 14.8622 19.1654 14.0122 18.5967C13.9937 18.5835 13.9782 18.5665 13.9667 18.5468C13.9552 18.5272 13.948 18.5053 13.9455 18.4827C13.9335 18.4227 13.9988 18.3494 13.9988 18.3494C13.9988 18.3494 20.6975 12.3947 20.8755 11.7701C20.8895 11.7221 20.8375 11.6981 20.7675 11.7187C20.3222 11.8827 12.6095 16.7527 11.7582 17.2907C11.6965 17.3088 11.6314 17.3123 11.5682 17.3007Z"
                              fill="white"></path>
                            <defs>
                              <linearGradient id="paint0_linear" x1="16.4895" y1="31.3334"
                                x2="16.4895" y2="0.666687" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#1D93D2"></stop>
                                <stop offset="1" stopColor="#38B0E3"></stop>
                              </linearGradient>
                            </defs>
                          </svg>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                      fill="none" className="d-none" data-project-partial-target="ethereumChainIcon">
                      <path
                        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                        fill="#627EEA"></path>
                      <path d="M16.498 4V12.87L23.995 16.22L16.498 4Z" fill="white" fillOpacity="0.602">
                      </path>
                      <path d="M16.498 4L9 16.22L16.498 12.87V4Z" fill="white"></path>
                      <path d="M16.498 21.968V27.995L24 17.616L16.498 21.968Z" fill="white"
                        fillOpacity="0.602"></path>
                      <path d="M16.498 27.995V21.967L9 17.616L16.498 27.995Z" fill="white"></path>
                      <path d="M16.498 20.573L23.995 16.22L16.498 12.872V20.573Z" fill="white"
                        fillOpacity="0.2"></path>
                      <path d="M9 16.22L16.498 20.573V12.872L9 16.22Z" fill="white" fillOpacity="0.602">
                      </path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                      fill="none" className="d-none" data-project-partial-target="binanceChainIcon">
                      <path
                        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                        fill="#F3BA2F"></path>
                      <path
                        d="M12.116 14.404L16 10.52L19.886 14.406L22.146 12.146L16 6L9.856 12.144L12.116 14.404ZM6 16L8.26 13.74L10.52 16L8.26 18.26L6 16ZM12.116 17.596L16 21.48L19.886 17.594L22.146 19.853L16 26L9.856 19.856L9.853 19.853L12.116 17.596ZM21.48 16L23.74 13.74L26 16L23.74 18.26L21.48 16ZM18.292 15.998H18.294V16L16 18.294L13.709 16.004L13.705 16L13.709 15.997L14.11 15.595L14.305 15.4L16 13.706L18.293 15.999L18.292 15.998Z"
                        fill="white"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                      fill="none" className="d-none" data-project-partial-target="polygonChainIcon">
                      <path
                        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                        fill="#8247E5"></path>
                      <path
                        d="M21.2959 12.5767C20.9122 12.3535 20.4133 12.3535 19.9911 12.5767L16.9978 14.2884L14.9638 15.4046L11.9705 17.1163C11.5867 17.3395 11.0878 17.3395 10.6657 17.1163L8.28633 15.7767C7.90258 15.5535 7.63394 15.1442 7.63394 14.6977V12.0558C7.63394 11.6093 7.86418 11.2 8.28633 10.9767L10.6273 9.67444C11.0111 9.45117 11.5099 9.45117 11.9321 9.67444L14.273 10.9767C14.6568 11.2 14.9255 11.6093 14.9255 12.0558V13.7675L16.9594 12.614V10.9023C16.9594 10.4558 16.7291 10.0465 16.307 9.82327L11.9705 7.36746C11.5867 7.1442 11.0878 7.1442 10.6657 7.36746L6.25237 9.82327C5.83026 10.0465 5.59999 10.4558 5.59999 10.9023V15.8512C5.59999 16.2977 5.83026 16.707 6.25237 16.9302L10.6657 19.3861C11.0494 19.6093 11.5483 19.6093 11.9705 19.3861L14.9638 17.7116L16.9978 16.5582L19.9911 14.8837C20.3749 14.6605 20.8738 14.6605 21.2959 14.8837L23.6369 16.1861C24.0207 16.4093 24.2893 16.8186 24.2893 17.2651V19.907C24.2893 20.3535 24.059 20.7628 23.6369 20.9861L21.2959 22.3256C20.9122 22.5488 20.4133 22.5488 19.9911 22.3256L17.6502 21.0233C17.2664 20.8 16.9978 20.3907 16.9978 19.9442V18.2325L14.9638 19.3861V21.0977C14.9638 21.5442 15.1941 21.9535 15.6162 22.1767L20.0295 24.6326C20.4133 24.8558 20.9122 24.8558 21.3343 24.6326L25.7476 22.1767C26.1313 21.9535 26.4 21.5442 26.4 21.0977V16.1488C26.4 15.7023 26.1697 15.293 25.7476 15.0698L21.2959 12.5767Z"
                        fill="white"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                      fill="none" className="d-none" data-project-partial-target="celoChainIcon">
                      <g clipPath="url(#clip0_1010_23853)">
                        <path
                          d="M13.0526 27.0526C17.5297 27.0526 21.1579 23.4244 21.1579 18.9474C21.1579 14.4703 17.5297 10.8421 13.0526 10.8421C8.57558 10.8421 4.94737 14.4703 4.94737 18.9474C4.94737 23.4244 8.57558 27.0526 13.0526 27.0526ZM13.0526 30C6.94863 30 2 25.0514 2 18.9474C2 12.8434 6.94863 7.89474 13.0526 7.89474C19.1566 7.89474 24.1053 12.8434 24.1053 18.9474C24.1053 25.0514 19.1566 30 13.0526 30Z"
                          fill="#FBCC5C"></path>
                        <path
                          d="M18.9474 21.1579C23.4244 21.1579 27.0527 17.5297 27.0527 13.0526C27.0527 8.57558 23.4244 4.94737 18.9474 4.94737C14.4703 4.94737 10.8421 8.57558 10.8421 13.0526C10.8421 17.5297 14.4703 21.1579 18.9474 21.1579ZM18.9474 24.1053C12.8434 24.1053 7.89476 19.1566 7.89476 13.0526C7.89476 6.94863 12.8434 2 18.9474 2C25.0514 2 30 6.94863 30 13.0526C30 19.1566 25.0514 24.1053 18.9474 24.1053Z"
                          fill="#35D07F"></path>
                        <path
                          d="M19.3129 24.1053C20.0792 23.1768 20.6274 22.0893 20.9192 20.9192C22.0863 20.6274 23.1739 20.0792 24.1053 19.3128C24.064 20.6657 23.7722 21.9979 23.2447 23.2476C21.9979 23.7722 20.6657 24.064 19.3129 24.1053ZM11.0809 11.0808C9.91371 11.3726 8.82613 11.9208 7.89476 12.6872C7.93602 11.3343 8.22781 10.0021 8.75539 8.75538C10.0021 8.23074 11.3373 7.93896 12.6872 7.89474C11.9209 8.82317 11.3727 9.91074 11.0809 11.0808Z"
                          fill="#5EA33B"></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_1010_23853">
                          <rect width="28" height="28" fill="white" transform="translate(2 2)"></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="ps--project-show__main">
              <div className="ps--project-show__logo">
                {JSON.parse(props.data.metaData).logo && <GetImage hash={JSON.parse(props.data.metaData).logo} />}
              </div>
              <div className="ps--project-show__information">
                <div className="ps--project-show__status">
                  <h1 className="ps--project-show__title">{props.data.productName}</h1>
                </div>
                <h2 className="ps--project-show__subtitle" data-project-partial-target="shortDescription"></h2>
              </div>
            </div>
            <div className="ps--project-show-grid">
              <div>
                <div className="ps--project-show__video">
                  {JSON.parse(props.data.metaData).headerImage && <GetImage hash={JSON.parse(props.data.metaData).headerImage} />}
                  {/* <img
                    src={img} /> */}
                  {/* {!img && <div className="ps--badge" data-project-partial-target="status">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ps--spinner" width="65px" height="65px"
                      viewBox="0 0 66 66">
                      <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33"
                        cy="33" r="30"></circle>
                    </svg>
                  </div>} */}
                </div>
                <div>
                  <div className="ps--project__tabs" data-controller="project-tabs">
                    <ul className="nav nav-tabs nav-border" id="projectTab" role="tablist">
                      <li className="nav-item">
                        <button className="nav-link active" id="description-tab"
                          data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab"
                          aria-controls="description" aria-selected="true">
                          Description
                        </button>
                      </li>
                      {/* <li className="nav-item">
                                <button className="nav-link" id="metrics-tab" data-bs-toggle="tab"
                                    data-bs-target="#metrics" type="button" role="tab" aria-controls="metrics"
                                    aria-selected="false">
                                    Token Sale
                                </button>
                            </li> */}
                    </ul>
                  </div>
                  <TabContent ido={props.data} active={"desc"} />
                </div>
              </div>
              <Sidebar ido={props.data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetails