import React, { useEffect, useState } from 'react'
import ipfs from '../../ipfs';

function GetImage(props) {

    const [img, setimg] = useState();

    async function getImage(hash) {
        console.log(hash, "hash");

        const result = await ipfs.get(`/ipfs/${hash}`);;

        console.log(result[0].content.toString('base64'), "results");

        setimg(result[0].content.toString('base64'));
    }

    useEffect(async () => {
        if (props.hash) {
            await getImage(props.hash)

        }
        return () => {

        }
    }, [])



    return (
        <div>
            {img && <img src={`data:image/png;base64,${img}`} />}
            {!img && <div>
                <img style={{ width: "70px", margin: "auto" }} src="https://acegif.com/wp-content/uploads/loading-36.gif" />
            </div>}
        </div>
    )
}

export default GetImage