
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BigNumber from 'bignumber.js'
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Project from './pages/Projects';
import ProjectDetail from './pages/ProjectDetail';



BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function App() {
  return (
    <div className="App">
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="projects" element={<Project />} />
          <Route path="project" element={<ProjectDetail />} />
          {/* <Route path="dashboard" element={<Dashboard />} /> */}

        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
