import axios from "axios";
import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import multicalls from "../multicall";
import ProjectDetails from "./ProjectDetails";
import ido from '../ABI/ido.json';
import './index.css';
import { useWallet } from "use-wallet";

const ProjectDetail = (props) => {

  const wallet = useWallet();
  console.log(window.location.pathname.split("/")[2]);

  const [data, setdata] = useState();

  const loadContractData = async (e, account) => {
    console.log(e);
    const calls = [
      // Balance of token in the LP contract
      {
        address: e.contractAddress,
        name: 'tradeValue',
        params: [],
      },
      {
        address: e.contractAddress,
        name: "isSaleFunded",
        params: []
      },
      {
        address: e.contractAddress,
        name: "hasStarted",
        params: []
      },
      {
        address: e.contractAddress,
        name: "endDate"
      },
      {
        address: e.contractAddress,
        name: "startDate"
      },
      {
        address: e.contractAddress,
        name: "tokensLeft"
      },
      {
        address: e.contractAddress,
        name: "availableTokens"
      },
      {
        address: e.contractAddress,
        name: "tokensForSale"
      },
      {
        address: e.contractAddress,
        name: "isOpen"
      },
      {
        address: e.contractAddress,
        name: "getMyPurchases",
        params: [account]
      },
      {
        address: e.contractAddress,
        name: "isWhitelisted",
        params: [account]
      }, {
        address: e.contractAddress,
        name: "getWhitelistedAddresses"
      }, {
        address: e.contractAddress,
        name: "owner"
      },
      {
        address: e.contractAddress,
        name: "isTokenSwapAtomic"
      },
      {
        address: e.contractAddress,
        name: "individualMaximumAmount"
      }
    ]

    const [
      tradeValue,
      funded,
      started,
      endDate,
      startDate,
      tokenLeft,
      availableTokens,
      tokensForSale,
      isOpen,
      myPurcahses,
      whiteListed,
      whitelistedAddress,
      owner,
      isTokenSwapAtomic,
      minimumPersonalAllocation
    ] = await multicalls(ido, calls)

    console.log(new BigNumber(tradeValue).toNumber());
    let obj = e;
    obj.tradeValue = new BigNumber(tradeValue).toNumber();
    obj.funded = funded[0];
    obj.started = started[0];
    obj.endDate = new BigNumber(endDate).toNumber();
    obj.startDate = new BigNumber(startDate).toNumber();
    obj.tokenLeft = new BigNumber(tokenLeft).toNumber();
    obj.availableTokens = new BigNumber(availableTokens).toNumber();
    obj.tokensForSale = new BigNumber(tokensForSale).toNumber();
    obj.totalRaise = tokensForSale - tokenLeft;
    obj.isOpen = isOpen[0];
    obj.myPurcahses = myPurcahses[0];
    obj.whiteListed = whiteListed[0];
    obj.whitelistedAddress = whitelistedAddress;
    obj.owner = owner;
    obj.isTokenSwapAtomic = isTokenSwapAtomic[0];
    obj.minimumPersonalAllocation = new BigNumber(minimumPersonalAllocation).toNumber();
    // return obj;
    // const array = data ? data : [];
    // array.push(obj);
    // console.log(array, "array");
    await setdata(obj);
  }

  const loadData = async (account) => {
    axios({
      url: "https://polkastarterdb.herokuapp.com/graphql",
      method: "post",
      data: {
        query: `
      query oneIdo($id:String!){
      ido(id:$id){
        id
        productLogo
        productName
        price
        description
        tokenAddress
        contractAddress
        images
        vestingSchedule
        status
        listed
        metaData
      }
    

    }
      `,
        variables: {
          id: window.location.search.split("=")[1],
        }
      },
    }).then(async (result) => {
      console.log("qwerrrrrrrrrr", result.data.data.ido);

      await loadContractData(result.data.data.ido, account);
      // result.data.data.allIdos.map(async (e) => {
      //   // console.log(e);
      //   if (e.contractAddress) {
      //     await loadContractData(e);
      //   }
      // })



      // setdata(result.data)
    });
  };

  useEffect(() => {

    if (wallet.status === "connected") {   // TODO: this true condition need to be solved
      console.log(wallet.account);
      loadData(wallet.account)
      console.log(window.location.search.split("=")[1]);
    }
    // if (wallet.status !== "connected") {
    //   wallet.connect();
    // }

    return () => {

    }
  }, [wallet.status, wallet])


  useEffect(() => {
    if (wallet.status !== "connected") {
      wallet.connect();
    }

    return () => {

    }
  }, [])



  return (
    <div>
      {data && wallet.status == "connected" && <ProjectDetails data={data} />}
      {!data && wallet.status == "connected" && <div>
        <img style={{ width: "20%", margin: "auto" }} src="https://acegif.com/wp-content/uploads/loading-36.gif" />
      </div>}
    </div>
  )
}


export default ProjectDetail;