import BigNumber from 'bignumber.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import Modal from 'react-modal/lib/components/Modal';
import { useWallet } from 'use-wallet';
import Web3 from 'web3';
import idoABI from '../../ABI/ido.json';
import { toWei } from '../../utils';

function Sidebar({ ido }) {

    const [investModal, setinvestModal] = useState(false);
    const [inputToken, setinputToken] = useState(0);

    const [mypurchases, setmypurchases] = useState([]);

    const wallet = useWallet();

    const handleApply = async (token) => {
        // setinvestModal(true);
        // let tokenInWei = toWei(token);
        const web3 = new Web3(Web3.givenProvider);
        const contractInstance = new web3.eth.Contract(idoABI, ido.contractAddress);
        console.log(contractInstance);

        const price = await contractInstance.methods.cost(token).call();

        console.log(price);
        const tx = await contractInstance.methods.swap(token).send({
            from: wallet.account,
            value: price
        })
        console.log(tx);
    }

    const handleReedemToken = async (id) => {
        const web3 = new Web3(Web3.givenProvider);
        const contractInstance = new web3.eth.Contract(idoABI, ido.contractAddress);
        console.log(contractInstance);
        // getPurchase
        const tx = await contractInstance.methods.redeemTokens(id).send({
            from: wallet.account
        })

        console.log(tx);
    }


    const handleMypurchase = async () => {
        const web3 = new Web3(Web3.givenProvider);

        const contractInstance = new web3.eth.Contract(idoABI, ido.contractAddress);

        const array = [];


        if (ido.myPurcahses.length > 0) {
            for (let i in ido.myPurcahses) {
                // console.log(ido.myPurcahses[i].toNumber());
                const data = await contractInstance.methods.getPurchase(ido.myPurcahses[i].toNumber()).call();
                console.log(data);
                data.id = ido.myPurcahses[i].toNumber();
                array.push(data);
            }
            await setmypurchases(array);
        }
    }


    const whitelist = async () => {

        const web3 = new Web3(Web3.givenProvider);
        const contractInstance = new web3.eth.Contract(idoABI, ido.contractAddress);
        console.log(contractInstance);
        const tx = await contractInstance.methods.add([wallet.account]).send({
            from: wallet.account
        })
        console.log(tx);
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: "9999999"
        },
    };


    useEffect(() => {

        handleMypurchase();

        return () => {

        }
    }, [mypurchases.length])


    return (
        <div>
            <div className="ps--project__sidebar" data-controller="project-sidebar" data-id="45"
                data-pool-is-whitelisted="false">
                <div className="ps--project__sidebar__wrapper">
                    <div className="ps--project__sidebar__goal">
                        <h5 className="ps--project__sidebar__goal__title">Fundraise Goal</h5>
                        {/* <h3 className="ps--project__sidebar__goal__raise" data-project-partial-target="totalRaise">
                        <svg xmlns="http://www.w3.org/2000/svg" className="ps--spinner" width="65px"
                            height="65px" viewBox="0 0 66 66">
                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33"
                                cy="33" r="30"></circle>
                        </svg>
                    </h3> */}
                        {/* <h4 className="ps--project__sidebar__goal__description mt-3 d-none"
                        data-project-sidebar-target="mainDescription">
                        <svg xmlns="http://www.w3.org/2000/svg" className="ps--spinner" width="65px"
                            height="65px" viewBox="0 0 66 66">
                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33"
                                cy="33" r="30"></circle>
                        </svg>
                    </h4> */}
                        {/* <h4 className="ps--project__sidebar__goal__description mt-3 d-none"
                        data-project-sidebar-target="secondaryDescription">
                        <svg xmlns="http://www.w3.org/2000/svg" className="ps--spinner" width="65px"
                            height="65px" viewBox="0 0 66 66">
                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33"
                                cy="33" r="30"></circle>
                        </svg>
                    </h4> */}
                    </div>
                    <div className="ps--project__sidebar__progress "
                        data-project-sidebar-target="progressHolder">
                        <div className="progress">
                            <div className="progress-bar ps--progress-animation ps--success" role="progressbar"
                                style={{ width: `${(ido.tokensForSale - ido.tokenLeft) / 100}%` }}
                                data-project-sidebar-target="progressBar">
                                <span data-project-sidebar-target="progressValue">0%</span>
                            </div>
                        </div>
                    </div>
                    <div className="ps--project__sidebar__inline-table d-block"
                        data-project-sidebar-target="metricsHolder">
                        <div className="ps--inline-table">
                            <div className="ps--inline-table__title">Allocation</div>
                            <div className="ps--inline-table__dotted"></div>
                            {/* <div className="ps--inline-table__value" data-project-partial-target="allocation">
                            <svg xmlns="http://www.w3.org/2000/svg" className="ps--spinner" width="65px"
                                height="65px" viewBox="0 0 66 66">
                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round"
                                    cx="33" cy="33" r="30"></circle>
                            </svg>
                        </div> */}
                        </div>
                        <div className="ps--inline-table">
                            <div className="ps--inline-table__title">Price per token</div>
                            <div className="ps--inline-table__dotted"></div>
                            {!ido.tradeValue && <div className="ps--inline-table__value" data-project-partial-target="tokenPrice">
                                <svg xmlns="http://www.w3.org/2000/svg" className="ps--spinner" width="65px"
                                    height="65px" viewBox="0 0 66 66">
                                    <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round"
                                        cx="33" cy="33" r="30"></circle>
                                </svg>
                            </div>}
                            {ido.tradeValue && <div className="ps--inline-table__value" data-project-partial-target="tokenPrice">
                                {ido.tradeValue / 10 ** 18} BNB
                            </div>}
                        </div>
                    </div>

                    {/* <div data-project-sidebar-target="subscribeFormHolder" className="d-none">
                    <form className="form-floating">
                        <input type="email" className="form-control" id="floatingInputInvalid"
                            placeholder="name@example.com" value="" />
                        <label >Email Address</label>
                    </form>
                    <div className="form-check mt-3">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label" >
                            Also notify me about all news, updates & more from Donor Vaults
                        </label>
                    </div>
                </div> */}
                    {mypurchases && mypurchases.length > 0 && mypurchases.map((e) => {
                        return (
                            <table>
                                <tr>
                                    <th>
                                        Purchase Id
                                    </th>
                                    <th>
                                        Value
                                    </th>
                                    <th>
                                        Date
                                    </th>
                                </tr>
                                {
                                    <tr>
                                        <th>
                                            {e.id}
                                        </th>
                                        <th>
                                            {e["0"]}
                                        </th>
                                        <th>
                                            {moment(e["3"]*1000).format("YYYY-MM-DD")}
                                        </th>
                                        <th>
                                            <button onClick={()=>{
                                                handleReedemToken(e.id)
                                            }}>
                                                Redeem
                                            </button>
                                        </th>
                                    </tr>
                                }
                            </table>
                        )
                    })
                    }
                    <div data-project-sidebar-target="subscribeFormCtas" className="d-none">
                        <p className="btn btn-primary w-100" >Notify me</p>
                    </div>
                    <div className="d-block" data-project-sidebar-target="whitelistOpenCtas">
                        {!ido.whiteListed && <div onClick={() => {
                            whitelist()
                            // if (inputToken > 0) { handleApply(inputToken) }
                            // setinvestModal(true);
                            // applyOnIdo(ido?.contractAddress, provider, 1, "0x3A3AC376CE597BCb91925805645F91Bc164bae01")
                        }} className="ps--project__sidebar__mobile-btn">
                            <p className="btn btn-primary w-100 mb-3"
                            >Apply Now</p>
                        </div>}
                        {ido.started && <div>
                            {ido.whiteListed && <input style={{ width: "100%", padding: "4px", fontSize: "1.5rem" }} type="number" onChange={(e) => { setinputToken(e.target.value) }} value={inputToken} />}
                            {ido.whiteListed && <div onClick={() => {
                                if (inputToken > 0) { handleApply(inputToken) }
                                // applyOnIdo(ido?.contractAddress, provider, 1, "0x3A3AC376CE597BCb91925805645F91Bc164bae01")
                            }} className="ps--project__sidebar__mobile-btn">
                                <p className="btn btn-primary w-100 mb-3"
                                >Invest</p>
                            </div>}
                        </div>}
                        {/* {ido?.startdate * 1000 > Date.now() && <div className="ps--project-card__table">

                            <div style={{ width: "100%", textAlign: "center", color: "#fff", fontWeight: "bold", fontSize: "25px" }}>
                                <Countdown date={Date.now() + (ido?.startdate * 1000 - Date.now())} />
                            </div>


                        </div>} */}
                        {/* <p className="btn btn-outline-secondary w-100" >Go to
                        Dashboard</p> */}
                    </div>
                    {!ido.started &&
                        <div>
                            <div class="ps--project__sidebar__timeleft" data-controller="countdown" data-id="59">
                                <h4 data-countdown-target="prefix">Sale Starts in</h4>

                                <p class="d-none text-center" id="reloadWarning">
                                    Please <a href="javascript:window.location.reload(true)">reload this page</a>
                                </p>
                                <div class="ps--countdown" id="js--countdown">
                                    <div class="ps--countdown__item" style={{ fontSize: "1.4rem" }}>

                                        <Countdown date={Date.now() + (ido.startDate * 1000 - Date.now())} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                    {ido.started && ido.isOpen && <div>
                        <div>
                            <div class="ps--project__sidebar__timeleft" data-controller="countdown" data-id="59">
                                <h4 data-countdown-target="prefix">Sale Ends in</h4>

                                <p class="d-none text-center" id="reloadWarning">
                                    Please <a href="javascript:window.location.reload(true)">reload this page</a>
                                </p>
                                <div class="ps--countdown" id="js--countdown">
                                    <div class="ps--countdown__item" style={{ fontSize: "1.4rem" }}>

                                        <Countdown date={Date.now() + (ido.endDate * 1000 - Date.now())} />
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <Countdown date={Date.now() + (ido.endDate * 1000 - Date.now())} /> */}
                    </div>}
                    <div className="d-none" data-project-sidebar-target="whitelistClosedCtas">

                        <p className="btn btn-primary w-100 mb-3">Application
                            Status</p>

                        <p className="btn btn-outline-secondary btn-icon w-100" >
                            How to get Whitelisted
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                fill="none">
                                <path
                                    d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 13C7.4 13 7 12.6 7 12C7 11.4 7.4 11 8 11C8.6 11 9 11.4 9 12C9 12.6 8.6 13 8 13ZM9.5 8.4C9 8.7 9 8.8 9 9V10H7V9C7 7.7 7.8 7.1 8.4 6.7C8.9 6.4 9 6.3 9 6C9 5.4 8.6 5 8 5C7.6 5 7.3 5.2 7.1 5.5L6.6 6.4L4.9 5.4L5.4 4.5C5.9 3.6 6.9 3 8 3C9.7 3 11 4.3 11 6C11 7.4 10.1 8 9.5 8.4Z"
                                    fill="black"></path>
                            </svg>
                        </p>
                    </div>
                    <div className="d-none" data-project-sidebar-target="saleLiveCtas" id="joinProjectMainCta">
                    </div>
                    <div className="d-none" data-project-sidebar-target="notWhitelistedCtas"
                        id="joinProjectNotWhitelistedCta">
                        <div className="ps--project__sidebar__mobile-btn">
                        </div>
                    </div>
                    <div className="d-none" data-project-sidebar-target="saleClosedCtas">
                    </div>
                </div>
                {/* <div className="ps--project__sidebar__timeleft d-none" data-controller="countdown" data-id="45">
                <h4 data-countdown-target="prefix"></h4>

                <p className="d-none text-center" id='reloadWarning'>
                    Please <p >reload this page</p>
                </p>
                <div className="ps--countdown" id="js--countdown">
                    <div className="ps--countdown__item">
                        <div className="ps--countdown__value days"></div>
                        <div className="ps--countdown__title">Days</div>
                    </div>
                    <span className="ps--countdown__divider">:</span>
                    <div className="ps--countdown__item">
                        <div className="ps--countdown__value hours"></div>
                        <span className="ps--countdown__title">Hours</span>
                    </div>
                    <span className="ps--countdown__divider">:</span>
                    <div className="ps--countdown__item">
                        <div className="ps--countdown__value minutes"></div>
                        <div className="ps--countdown__title">Minutes</div>
                    </div>
                    <span className="ps--countdown__divider">:</span>
                    <div className="ps--countdown__item">
                        <div className="ps--countdown__value seconds"></div>
                        <div className="ps--countdown__title">Seconds</div>
                    </div>
                </div>
            </div> */}
                {/* <Modal
                    isOpen={investModal}
                    onRequestClose={() => {
                        setinvestModal(false)
                    }}
                    style={customStyles}
                    contentLabel="Swap"
                >
                    <h2 >Hello</h2>
                    <button onClick={() => {
                        setinvestModal(false)
                    }}>close</button>
                    <div>I am a modal</div>
                    <form>
                        <input />
                        <button>tab navigation</button>
                        <button>stays</button>
                        <button>inside</button>
                        <button>the modal</button>
                    </form>
                </Modal> */}
            </div>
        </div>
    )
}

export default Sidebar