import React from 'react'
import GetImage from '../../Projects/GetImage'

function TabContent({ ido }) {
    return (
        <div><div className="tab-content" id="projectTabContent">
            <div className="tab-pane fade show active" id="description" role="tabpanel"
                aria-labelledby="description-tab">
                <div>
                    <h6 style={{ color: "#c0c0c0" }}>
                        Product
                    </h6>
                    <h2 style={{ color: "#c0c0c0" }}>
                        {JSON.parse(ido.description).product.heading}
                    </h2>
                    <p style={{ color: "#c0c0c0" }}>
                        {JSON.parse(ido.description).product.description}
                    </p>
                    {JSON.parse(ido.description).product.img && <GetImage hash={JSON.parse(ido.description).product.img} />}
                    {/* <img src={JSON.parse(idoDesc)[e].image} alt="image" /> */}
                </div>
                <div>
                    <h6 style={{ color: "#c0c0c0" }}>
                        Problem
                    </h6>
                    <h2 style={{ color: "#c0c0c0" }}>
                        {JSON.parse(ido.description).problem.heading}
                    </h2>
                    <p style={{ color: "#c0c0c0" }}>
                        {JSON.parse(ido.description).problem.description}
                    </p>
                    {JSON.parse(ido.description).problem.img && <GetImage hash={JSON.parse(ido.description).problem.img} />}
                    {/* <img src={JSON.parse(idoDesc)[e].image} alt="image" /> */}
                </div>
                <div>
                    <h6 style={{ color: "#c0c0c0" }}>
                        Solution
                    </h6>
                    <h2>
                        {JSON.parse(ido.description).solution.heading}
                    </h2>
                    <p>
                        {JSON.parse(ido.description).solution.description}
                    </p>
                    {JSON.parse(ido.description).solution.img && <GetImage hash={JSON.parse(ido.description).solution.img} />}
                    {/* <img src={JSON.parse(idoDesc)[e].image} alt="image" /> */}
                </div>
                <div>
                    <h6 style={{ color: "#c0c0c0" }}>
                        Business Modal
                    </h6>
                    <h2>
                        {JSON.parse(ido.description).businessModal.heading}
                    </h2>
                    <p>
                        {JSON.parse(ido.description).businessModal.description}
                    </p>
                    {JSON.parse(ido.description).businessModal.img && <GetImage hash={JSON.parse(ido.description).businessModal.img} />}
                    {/* <img src={JSON.parse(idoDesc)[e].image} alt="image" /> */}
                </div>
                <div>
                    <h6 style={{ color: "#c0c0c0" }}>
                        Market
                    </h6>
                    <h2>
                        {JSON.parse(ido.description).market.heading}
                    </h2>
                    <p>
                        {JSON.parse(ido.description).market.description}
                    </p>
                    {JSON.parse(ido.description).market.img && <GetImage hash={JSON.parse(ido.description).market.img} />}
                    {/* <img src={JSON.parse(idoDesc)[e].image} alt="image" /> */}
                </div><div>
                    <h6 style={{ color: "#c0c0c0" }}>
                        Team
                    </h6>
                    <h2>
                        {JSON.parse(ido.description).team.heading}
                    </h2>
                    <p>
                        {JSON.parse(ido.description).team.description}
                    </p>
                    {JSON.parse(ido.description).team.img && <GetImage hash={JSON.parse(ido.description).team.img} />}
                    {/* <img src={JSON.parse(idoDesc)[e].image} alt="image" /> */}
                </div>
                <div>
                    <h6 style={{ color: "#c0c0c0" }}>
                        Investor
                    </h6>
                    <h2>
                        {JSON.parse(ido.description).investor.heading}
                    </h2>
                    <p>
                        {JSON.parse(ido.description).investor.description}
                    </p>
                    {JSON.parse(ido.description).investor.img && <GetImage hash={JSON.parse(ido.description).investor.img} />}
                    {/* <img src={JSON.parse(idoDesc)[e].image} alt="image" /> */}
                </div>
                <div>
                    <h6 style={{ color: "#c0c0c0" }}>
                        Utility
                    </h6>
                    <h2>
                        {JSON.parse(ido.description).utility.heading}
                    </h2>
                    <p>
                        {JSON.parse(ido.description).utility.description}
                    </p>
                    {JSON.parse(ido.description).utility.img && <GetImage hash={JSON.parse(ido.description).utility.img} />}
                    {/* <img src={JSON.parse(idoDesc)[e].image} alt="image" /> */}
                </div>
                {/* {keys && idoDesc && keys.split(",").map((e, i) => {
                    // getImage({ hash: JSON.parse(idoDesc)[e].image, i })
                    return (
                        <div>
                            <h6 style={{ color: "#c0c0c0" }}>
                                {e.toUpperCase()}
                            </h6>
                            <h2>
                                {JSON.parse(idoDesc)[e].head}
                            </h2>
                            <p>
                                {JSON.parse(idoDesc)[e].desc}
                            </p>
                            <img src={JSON.parse(idoDesc)[e].image} alt="image" />
                        </div>
                    )
                })} */}
                <div data-controller="project-description" data-id="45">
                    <div className="ps--markdown" data-target="project-description.markdown"></div>
                    <div className="ps--project-menu__wrapper">
                        {/* {keys} */}
                        {/* <div className="ps--project-menu" data-target="project-description.links">


                </div> */}
                    </div>
                    <div className="ps--markdown ps--project-show__about"
                        data-controller="project-social-meta" data-id="45">
                        <h2>About </h2>
                        <div className="ps--project-show__about__grid">
                            <div className="ps--project-show__about__grid__item">
                                <div className="ps--project-show__about__item">
                                    <div className="ps--project-show__about__title">Website</div>
                                    <div className="ps--project-show__about__content">
                                        <p data-project-social-meta-target="website"
                                        ></p>
                                    </div>
                                </div>
                                <div className="ps--project-show__about__item"
                                    data-project-social-meta-target="whitepaper"></div>

                            </div>
                            <div className="ps--project-show__about__grid__item">
                                <div className="ps--project-show__about__item">
                                    <div className="ps--project-show__about__title">Social Media</div>
                                    <div className="ps--project-show__about__social">
                                        <p onClick={() => {
                                            window.open(JSON.parse(ido.metaData).socialMedias.twitter)
                                        }} data-project-social-meta-target="twitter"
                                            className="" >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="16" viewBox="0 0 16 16" fill="none">
                                                <path
                                                    d="M5.026 15C11.064 15 14.367 9.99702 14.367 5.66602C14.367 5.52602 14.367 5.38402 14.361 5.24402C15.0041 4.77851 15.5591 4.20217 16 3.54202C15.3993 3.80687 14.7628 3.98143 14.111 4.06002C14.7975 3.64966 15.3117 3.00399 15.558 2.24302C14.913 2.62515 14.207 2.8934 13.471 3.03602C12.9762 2.50905 12.3214 2.15994 11.6081 2.04278C10.8948 1.92562 10.1627 2.04694 9.52534 2.38796C8.88796 2.72897 8.38081 3.27065 8.08245 3.92908C7.78409 4.58751 7.71118 5.32595 7.875 6.03002C6.56974 5.96457 5.29282 5.62549 4.12704 5.03476C2.96127 4.44404 1.93268 3.61487 1.108 2.60102C0.68934 3.3241 0.561574 4.17942 0.750646 4.99329C0.939718 5.80715 1.43145 6.51855 2.126 6.98302C1.60554 6.96534 1.09652 6.82558 0.64 6.57502V6.62002C0.640897 7.3775 0.903307 8.11144 1.38287 8.69778C1.86244 9.28412 2.52975 9.68689 3.272 9.83802C2.99026 9.91564 2.69923 9.95433 2.407 9.95302C2.20098 9.95365 1.99538 9.93456 1.793 9.89602C2.00279 10.5481 2.41127 11.1182 2.96125 11.5265C3.51122 11.9348 4.17513 12.1609 4.86 12.173C3.69656 13.0868 2.2594 13.5824 0.78 13.58C0.519321 13.5811 0.258823 13.5661 0 13.535C1.50151 14.4923 3.2453 15.0006 5.026 15Z"
                                                    fill="black"></path>
                                            </svg>
                                        </p> <p
                                            onClick={() => {
                                                window.open(JSON.parse(ido.metaData).medium)
                                            }}
                                            data-project-social-meta-target="medium" className=" "
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="16" viewBox="0 0 16 16" fill="none">
                                                <path
                                                    d="M2.25627 4.35257C2.27498 4.15715 2.20449 3.964 2.06646 3.83248L0.660406 2.04183V1.77435H5.0262L8.40072 9.59818L11.3675 1.77435H15.5294V2.04183L14.3272 3.26036C14.2236 3.34387 14.1721 3.48114 14.1937 3.617V12.5702C14.1721 12.7061 14.2236 12.8433 14.3272 12.9269L15.5013 14.1453V14.4128H9.59586V14.1453L10.8121 12.8971C10.9316 12.7708 10.9316 12.7336 10.9316 12.5405V5.30362L7.55006 14.3831H7.09309L3.15615 5.30362V11.3888C3.12332 11.6447 3.2037 11.9022 3.37409 12.0872L4.9559 14.1157V14.3831H0.470589V14.1157L2.0524 12.0872C2.22154 11.9019 2.29723 11.6426 2.25627 11.3888V4.35257Z"
                                                    fill="black"></path>
                                            </svg>
                                        </p> <p
                                            onClick={() => {
                                                window.open(JSON.parse(ido.metaData).socialMedias.telegram)
                                            }}
                                            data-project-social-meta-target="telegram"
                                            className=" "  >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="16" viewBox="0 0 16 16" fill="none">
                                                <g clipPath="url(#svg-telegram)">
                                                    <path
                                                        d="M15.968 1.68397C15.9554 1.62493 15.9273 1.57032 15.8865 1.52583C15.8457 1.48134 15.7937 1.44859 15.736 1.43097C15.5258 1.38905 15.3081 1.4046 15.106 1.47597C15.106 1.47597 1.087 6.51397 0.286 7.07197C0.114 7.19297 0.0560002 7.26197 0.0270002 7.34397C-0.111 7.74397 0.32 7.91697 0.32 7.91697L3.933 9.09397C3.994 9.10498 4.05675 9.10121 4.116 9.08297C4.938 8.56397 12.386 3.86097 12.816 3.70297C12.884 3.68297 12.934 3.70297 12.916 3.75197C12.744 4.35197 6.31 10.071 6.275 10.106C6.25793 10.1202 6.24455 10.1384 6.23602 10.159C6.22749 10.1795 6.22405 10.2018 6.226 10.224L5.889 13.752C5.889 13.752 5.747 14.852 6.845 13.752C7.624 12.973 8.372 12.327 8.745 12.014C9.987 12.872 11.324 13.82 11.901 14.314C11.9976 14.4081 12.1123 14.4817 12.2381 14.5303C12.3639 14.5789 12.4982 14.6016 12.633 14.597C12.799 14.5765 12.9548 14.506 13.0798 14.3949C13.2048 14.2838 13.2931 14.1374 13.333 13.975C13.333 13.975 15.894 3.69997 15.979 2.31697C15.987 2.18197 16 2.09997 16 1.99997C16.0037 1.89368 15.9929 1.78738 15.968 1.68397Z"
                                                        fill="black"></path>
                                                </g>
                                                <defs>
                                                    <clipPath id="svg-telegram">
                                                        <rect width="16" height="16" fill="white">
                                                        </rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </p> <p
                                            data-project-social-meta-target="linkedin"
                                            className="d-none"  >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="16" viewBox="0 0 16 16" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                    d="M0 1.146C0 0.513 0.526 0 1.175 0H14.825C15.474 0 16 0.513 16 1.146V14.854C16 15.487 15.474 16 14.825 16H1.175C0.526 16 0 15.487 0 14.854V1.146ZM4.943 13.394V6.169H2.542V13.394H4.943ZM3.743 5.182C4.58 5.182 5.101 4.628 5.101 3.934C5.086 3.225 4.581 2.686 3.759 2.686C2.937 2.686 2.4 3.226 2.4 3.934C2.4 4.628 2.921 5.182 3.727 5.182H3.743ZM8.651 13.394H6.251C6.251 13.394 6.281 6.847 6.251 6.169H8.651V7.192C8.64564 7.20032 8.64031 7.20865 8.635 7.217H8.651V7.192C8.971 6.699 9.541 5.999 10.816 5.999C12.396 5.999 13.58 7.032 13.58 9.251V13.394H11.18V9.529C11.18 8.557 10.832 7.895 9.963 7.895C9.299 7.895 8.904 8.342 8.73 8.773C8.667 8.927 8.651 9.143 8.651 9.359V13.394Z"
                                                    fill="black"></path>
                                            </svg>
                                        </p> <p
                                            onClick={() => {
                                                window.open(JSON.parse(ido.metaData).socialMedias.discord)
                                            }}
                                            data-project-social-meta-target="discord" className=" "
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="16" viewBox="0 0 16 16" fill="none">
                                                <g clipPath="url(#svg-discord)">
                                                    <path
                                                        d="M6.552 6.71203C6.32909 6.73036 6.12123 6.83187 5.9697 6.99637C5.81817 7.16088 5.73405 7.37636 5.73405 7.60003C5.73405 7.82369 5.81817 8.03917 5.9697 8.20368C6.12123 8.36819 6.32909 8.46969 6.552 8.48803C6.66392 8.4834 6.77383 8.45674 6.87543 8.40959C6.97704 8.36244 7.06835 8.29572 7.14414 8.21324C7.21993 8.13076 7.27872 8.03414 7.31713 7.92892C7.35554 7.8237 7.37283 7.71194 7.368 7.60003C7.3735 7.48796 7.35668 7.37591 7.31852 7.2704C7.28035 7.16489 7.22161 7.06801 7.14569 6.98539C7.06977 6.90278 6.97819 6.83606 6.87627 6.78914C6.77436 6.74221 6.66413 6.716 6.552 6.71203ZM9.472 6.71203C9.29322 6.69692 9.11405 6.73614 8.95793 6.82456C8.80181 6.91297 8.67602 7.04645 8.59702 7.20754C8.51802 7.36863 8.48949 7.54981 8.51517 7.72738C8.54085 7.90495 8.61954 8.07063 8.74093 8.20274C8.86233 8.33484 9.02078 8.42723 9.19555 8.46779C9.37032 8.50836 9.55326 8.49521 9.72044 8.43008C9.88762 8.36496 10.0312 8.25087 10.1325 8.10277C10.2338 7.95467 10.288 7.77944 10.288 7.60003C10.2928 7.48811 10.2755 7.37635 10.2371 7.27113C10.1987 7.16591 10.1399 7.06929 10.0641 6.98681C9.98835 6.90433 9.89704 6.83761 9.79543 6.79046C9.69383 6.74331 9.58392 6.71665 9.472 6.71203Z"
                                                        fill="black"></path>
                                                    <path
                                                        d="M13.36 0H2.64C2.42411 0.000524649 2.21044 0.0435677 2.01118 0.126671C1.81192 0.209775 1.63098 0.331312 1.47869 0.484343C1.3264 0.637375 1.20575 0.818903 1.12361 1.01856C1.04148 1.21822 0.999479 1.43211 1 1.648V12.464C0.999479 12.6799 1.04148 12.8938 1.12361 13.0934C1.20575 13.2931 1.3264 13.4746 1.47869 13.6277C1.63098 13.7807 1.81192 13.9022 2.01118 13.9853C2.21044 14.0684 2.42411 14.1115 2.64 14.112H11.712L11.288 12.632L12.312 13.584L13.28 14.484L15 16V1.648C15.0005 1.43211 14.9585 1.21822 14.8764 1.01856C14.7943 0.818903 14.6736 0.637375 14.5213 0.484343C14.369 0.331312 14.1881 0.209775 13.9888 0.126671C13.7896 0.0435677 13.5759 0.000524649 13.36 0V0ZM10.272 10.448C10.272 10.448 9.984 10.1 9.74401 9.8C10.3239 9.6635 10.8368 9.32627 11.192 8.848C10.9041 9.03957 10.5955 9.19792 10.272 9.32C9.89984 9.47886 9.51061 9.59429 9.11201 9.664C8.42685 9.79024 7.72416 9.78753 7.04 9.656C6.63834 9.57741 6.2447 9.46226 5.864 9.312C5.54362 9.18857 5.23778 9.03029 4.952 8.84C5.29436 9.30824 5.78956 9.64215 6.35201 9.784C6.11201 10.084 5.816 10.448 5.816 10.448C5.34078 10.4608 4.86969 10.3566 4.44421 10.1446C4.01872 9.93255 3.65189 9.61916 3.376 9.232C3.40197 7.61017 3.79587 6.01541 4.528 4.568C5.17229 4.06199 5.95777 3.76848 6.776 3.728L6.856 3.828C6.08764 4.01915 5.37071 4.37693 4.756 4.876C4.756 4.876 4.932 4.776 5.228 4.644C5.80328 4.38289 6.41615 4.21415 7.044 4.144C7.08879 4.13473 7.13429 4.12938 7.18 4.128C7.7143 4.05448 8.25547 4.04508 8.792 4.1C9.63708 4.19651 10.4551 4.45741 11.2 4.868C10.6151 4.39357 9.93611 4.04864 9.208 3.856L9.32 3.728C10.1382 3.76848 10.9237 4.06199 11.568 4.568C12.3001 6.01541 12.694 7.61017 12.72 9.232C12.4419 9.61877 12.0735 9.93173 11.6468 10.1437C11.2202 10.3556 10.7482 10.4601 10.272 10.448Z"
                                                        fill="black"></path>
                                                </g>
                                                <defs>
                                                    <clipPath id="svg-discord">
                                                        <rect width="16" height="16" fill="white">
                                                        </rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </p> <p
                                            data-project-social-meta-target="github" className=" "
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="16" viewBox="0 0 16 16" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                    d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C12.1382 15.054 13.5183 14.0333 14.496 12.6718C15.4737 11.3102 15.9997 9.67624 16 8C16 3.58 12.42 0 8 0Z"
                                                    fill="black"></path>
                                            </svg>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ps--project-show__about__logo">
                                {JSON.parse(ido.metaData).logo && <GetImage hash={JSON.parse(ido.metaData).logo} />}
                                {/* <img className=""
                                    src="https://storage.googleapis.com/polkastarter-production-assets/3wdekv3g703ycx5cwvetq983tk90" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-pane fade" id="metrics" role="tabpanel" aria-labelledby="metrics-tab">
                <div className="ps--alert ps--success  ">
                    <div className="ps--alert__icon"><svg xmlns="http://www.w3.org/2000/svg" width="28"
                        height="29" viewBox="0 0 28 29" fill="none">
                        <g clipPath="url(#svg-check)">
                            <path
                                d="M14 0.5C11.2311 0.5 8.52431 1.32109 6.22202 2.85943C3.91973 4.39776 2.12532 6.58427 1.06569 9.14243C0.006066 11.7006 -0.271181 14.5155 0.269012 17.2313C0.809206 19.947 2.14258 22.4416 4.10051 24.3995C6.05845 26.3574 8.55301 27.6908 11.2687 28.231C13.9845 28.7712 16.7994 28.4939 19.3576 27.4343C21.9157 26.3747 24.1022 24.5803 25.6406 22.278C27.1789 19.9757 28 17.2689 28 14.5C27.9892 10.7903 26.5108 7.23558 23.8876 4.61241C21.2644 1.98923 17.7097 0.510773 14 0.5V0.5ZM11.6667 20.8163L5.35034 14.5L7.00001 12.8503L11.6667 17.517L21 8.18367L22.6497 9.83333L11.6667 20.8163Z"
                                fill="#0FF09E"></path>
                        </g>
                        <defs>
                            <clipPath id="svg-check">
                                <rect width="28" height="28" fill="white"
                                    transform="translate(0 0.5)"></rect>
                            </clipPath>
                        </defs>
                    </svg>
                    </div>
                    <div className="ps--alert__info">
                        <h2>You’re whitelisted for Galaxy Fight Club IDO</h2>
                        <p>Checked about 7 hours ago</p>
                    </div>
                    <div className="ps--alert__actions">
                        <p className="btn btn-sm btn-outline-secondary"
                            data-disable-width="<div className='loader'></div>"
                        >Dashboard</p>
                    </div>
                </div>
                <div className="ps--table__responsive" data-id="45"
                    data-controller="project-sale-metrics">
                    <table className="ps--table ps--table--small" cellSpacing="0" cellPadding="0"
                    >
                        <thead>
                            <tr>
                                <th className="text-start"> Project Key Metrics </th>
                                <th className="text-end"> </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-start"> <span className="text-color-03">Sale
                                    Price</span> </td>
                                <td className="text-end" data-project-sale-metrics-target="salePrice">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="ps--spinner"
                                        width="65px" height="65px" viewBox="0 0 66 66">
                                        <circle className="path" fill="none" strokeWidth="6"
                                            strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-start"> <span className="text-color-03">Sale Start Time
                                    (UTC)</span> </td>
                                <td className="text-end"
                                    data-project-sale-metrics-target="saleStartTime"> <svg
                                        xmlns="http://www.w3.org/2000/svg" className="ps--spinner"
                                        width="65px" height="65px" viewBox="0 0 66 66">
                                        <circle className="path" fill="none" strokeWidth="6"
                                            strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-start"> <span className="text-color-03">Sale End Time
                                    (UTC)</span> </td>
                                <td className="text-end" data-project-sale-metrics-target="saleEndTime">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="ps--spinner"
                                        width="65px" height="65px" viewBox="0 0 66 66">
                                        <circle className="path" fill="none" strokeWidth="6"
                                            strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-start"> <span className="text-color-03">Token
                                    Distribution (UTC)</span> </td>
                                <td className="text-end"
                                    data-project-sale-metrics-target="saleDistributionTime"> <svg
                                        xmlns="http://www.w3.org/2000/svg" className="ps--spinner"
                                        width="65px" height="65px" viewBox="0 0 66 66">
                                        <circle className="path" fill="none" strokeWidth="6"
                                            strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-start"> <span className="text-color-03">Initial Market
                                    Cap</span> </td>
                                <td className="text-end"
                                    data-project-sale-metrics-target="initialMarketCap"> <svg
                                        xmlns="http://www.w3.org/2000/svg" className="ps--spinner"
                                        width="65px" height="65px" viewBox="0 0 66 66">
                                        <circle className="path" fill="none" strokeWidth="6"
                                            strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-start"> <span className="text-color-03">Initial Token
                                    Circulation</span> </td>
                                <td className="text-end"
                                    data-project-sale-metrics-target="initialCirculation"> <svg
                                        xmlns="http://www.w3.org/2000/svg" className="ps--spinner"
                                        width="65px" height="65px" viewBox="0 0 66 66">
                                        <circle className="path" fill="none" strokeWidth="6"
                                            strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div></div>
    )
}

export default TabContent